import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ListsWidget8, MixedWidget10, MixedWidget11, TablesWidget10,} from '../../../_metronic/partials/widgets'
import {Charts} from "../../modules/widgets/components/Charts";
import Chart from "react-apexcharts";
import {getCSSVariableValue} from "../../../_metronic/assets/ts/_utils";
import React from "react";

const DashboardPage = () =>
	(
		<>
			{/* begin::Row */}
			<div className='row gy-5 g-xl-8'>
				<div className='col-xxl-6'>
					<MixedWidget10
						className='card-xxl-stretch mb-5 mb-xl-8'
						chartColor='primary'
						chartHeight='150px'
					/>
				</div>
				<div className='col-xxl-6'>
					<MixedWidget11
						className='card-xxl-stretch mb-5 mb-xl-8'
						chartColor='primary'
						chartHeight='175px'
					/>
				</div>
			</div>
			{/* end::Row */}

			<div className='row gy-5 g-xl-8'>
				<div className='col-xxl'>
					<div className='card card-xxl-stretch mb-5 mb-xl-8'>
						<div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
							<div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
								<div className='me-2'>
									<span className='fw-bold text-gray-800 d-block fs-3'>Top 5 Customers</span>

									{/*<span className='text-gray-400 fw-semibold'>Oct 8 - Oct 26 2023</span>*/}
								</div>

								{/*<div className={`fw-bold fs-3 text-blue`}>Rp 14.515.300</div>*/}
							</div>
							<div className='ps-4 pe-4'>
								<Chart
									options={dataBar.options}
									series={dataBar.series}
									type={'bar'}
									height={350}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='row gy-5 g-xl-8'>
				<div className='col-xxl-4'>
					<div className='card card-xxl-stretch mb-5 mb-xl-8'>
						<div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
							<div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'
							     style={{justifyContent: 'center'}}>
								<div className='me-2'>
									<span className='fw-bold text-gray-800 d-block fs-6'
									      style={{textAlign: 'center'}}>Top 5 Outlets</span>

									{/*<span className='text-gray-400 fw-semibold' style={{textAlign: 'center'}}>Oct 8 - Oct 26 2023</span>*/}
								</div>

								{/*<div className={`fw-bold fs-3 text-blue`}>Rp 14.515.300</div>*/}
							</div>
							<div className='ps-2 pe-2 pb-4'>
								<Chart
									options={dataPieOutlet.options}
									series={dataPieOutlet.series}
									type={'pie'}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='col-xxl-4'>
					<div className='card card-xxl-stretch mb-5 mb-xl-8'>
						<div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
							<div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'
							     style={{justifyContent: 'center'}}>
								<div className='me-2'>
									<span className='fw-bold text-gray-800 d-block fs-6' style={{textAlign: 'center'}}>Top 5 City</span>

									{/*<span className='text-gray-400 fw-semibold' style={{textAlign: 'center'}}>Oct 8 - Oct 26 2023</span>*/}
								</div>

								{/*<div className={`fw-bold fs-3 text-blue`}>Rp 14.515.300</div>*/}
							</div>
							<div className='ps-2 pe-2 pb-4'>
								<Chart
									options={dataPieCity.options}
									series={dataPieCity.series}
									type={'pie'}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='col-xxl-4'>
					<div className='card card-xxl-stretch mb-5 mb-xl-8'>
						<div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
							<div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'
							     style={{justifyContent: 'center'}}>
								<div className='me-2'>
									<span className='fw-bold text-gray-800 d-block fs-6' style={{textAlign: 'center'}}>Top 5 Area</span>

									{/*<span className='text-gray-400 fw-semibold' style={{textAlign: 'center'}}>Oct 8 - Oct 26 2023</span>*/}
								</div>

								{/*<div className={`fw-bold fs-3 text-blue`}>Rp 14.515.300</div>*/}
							</div>
							<div className='ps-2 pe-2 pb-4'>
								<Chart
									options={dataPieArea.options}
									series={dataPieArea.series}
									type={'pie'}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* begin::Row */}
			<div className='row gy-5 g-xl-8'>
				<div className='col-xxl'>
					<Charts/>
				</div>
			</div>
			{/* end::Row */}

			{/* begin::Row */}
			<div className='row gy-5 gx-xl-8'>
				<div className='col-xxl-4'>
					<ListsWidget8 className='card-xxl-stretch mb-xl-3'/>
				</div>
				<div className='col-xl-8'>
					<TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8'/>
				</div>
			</div>
			{/* end::Row */}
		</>
	)

const DashboardWrapper = () => {
	const intl = useIntl()

	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
			<DashboardPage/>
		</>
	)
}

export const dataBar: any = {
	options: {
		chart: {
			id: "basic-bar"
		},
		xaxis: {
			categories: ['Fahmi', 'Triand', 'Asep', 'Febra', 'Dina']
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '80%',
				borderRadius: 10,
			}
		},
		stroke: {
			width: 0,
			colors: ['#fff'],
		},
		grid: {
			padding: {
				top: 10,
			},
			borderColor: getCSSVariableValue('--bs-gray-300'),
			strokeDashArray: 4,
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
		// title: {
		//   text: 'Top 5 Consumers',
		//   style: {
		//     fontSize: 17,
		//     fontFamily: 'Inter',
		//     fontWeight: '600',
		//     color: '#252f4a'
		//   }
		// }
	},
	series: [
		{
			name: "Transaction",
			data: [
				{
					x: 'Fahmi',
					y: 125,
					fillColor: '#a963ff',
					strokeColor: '#a963ff'
				},
				{
					x: 'Triand',
					y: 23,
					fillColor: '#00f69e',
					strokeColor: '#00f69e'
				},
				{
					x: 'Asep',
					y: 41,
					fillColor: '#e87c00',
					strokeColor: '#e87c00'
				},
				{
					x: 'Febra',
					y: 76,
					fillColor: '#002baf',
					strokeColor: '#002baf'
				},
				{
					x: 'Dina',
					y: 58,
					fillColor: '#ff6384',
					strokeColor: '#ff6384'
				}
			]
		}
	],
};

export const dataPieOutlet: any = {
	series: [12, 17, 6, 5, 22],
	options: {
		chart: {
			id: "basic-pie-outlet"
		},
		labels: ['Outlet 1', 'Outlet 2', 'Outlet 3', 'Outlet 4', 'Outlet 5'],
		legend: {
			position: 'bottom',
			horizontalAlign: 'left',
			fontSize: 10,
			markers: {
				width: 10,
				height: 10
			}
		},
		plotOptions: {
			pie: {
				dataLabels: {
					offset: -5
				}
			}
		},
		// title: {
		//   text: 'Top 5 Consumers',
		//   style: {
		//     fontSize: 17,
		//     fontFamily: 'Inter',
		//     fontWeight: '600',
		//     color: '#252f4a'
		//   }
		// },
		// dataLabels: {
		//   enabled: true,
		//   formatter: function (val) {
		//     return val + "%"
		//   }
		// }
	},
};

export const dataPieCity: any = {
	series: [8, 21, 44, 15, 7],
	options: {
		chart: {
			id: "basic-pie-city"
		},
		labels: ['Jakarta', 'Bandung', 'Denpasar', 'Kupang', 'Ambon'],
		legend: {
			position: 'bottom',
			horizontalAlign: 'left',
			fontSize: 10,
			markers: {
				width: 10,
				height: 10
			}
		},
		plotOptions: {
			pie: {
				dataLabels: {
					offset: -5
				}
			}
		},
		// title: {
		//   text: 'Top 5 Consumers',
		//   style: {
		//     fontSize: 17,
		//     fontFamily: 'Inter',
		//     fontWeight: '600',
		//     color: '#252f4a'
		//   }
		// },
		// dataLabels: {
		//   enabled: true,
		//   formatter: function (val) {
		//     return val + "%"
		//   }
		// }
	},
};

export const dataPieArea: any = {
	series: [28, 41, 14, 21, 2],
	options: {
		chart: {
			id: "basic-pie-area"
		},
		labels: ['Pasteur', 'Kuningan', 'Tomang', 'Kuta', 'Kelapa Lima'],
		legend: {
			position: 'bottom',
			horizontalAlign: 'left',
			fontSize: 10,
			markers: {
				width: 10,
				height: 10
			}
		},
		plotOptions: {
			pie: {
				dataLabels: {
					offset: -5
				}
			}
		},
		// title: {
		//   text: 'Top 5 Consumers',
		//   style: {
		//     fontSize: 17,
		//     fontFamily: 'Inter',
		//     fontWeight: '600',
		//     color: '#252f4a'
		//   }
		// },
		// dataLabels: {
		//   enabled: true,
		//   formatter: function (val) {
		//     return val + "%"
		//   }
		// }
	},
};

export {DashboardWrapper}

export type BaseRequest<T> = {
  limit: number | 10 | 30 | 50 | 100
  page: number | 1
  order?: 'ASC' | 'DESC'
  sort?: string | 'created_at'
  filter?: T
}

export const initialBaseRequest: BaseRequest<any> = {
  limit: 10,
  page: 1,
  order: 'ASC',
  sort: 'created_at',
  filter: null
}

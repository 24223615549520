import {FC, lazy, Suspense} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const CustomersPage = lazy(() => import('../modules/apps/customer-data/CustomersPage'))
  const LoyaltyPage = lazy(() => import('../modules/apps/loyalty-point/LoyaltyPage'))
  const PosPage = lazy(() => import('../modules/apps/pos/PosPage'))
  const TransactionPage = lazy(() => import('../modules/apps/transaction-history/TransactionPage'))
  const CostPage = lazy(() => import('../modules/apps/cost/CostConfigurationPage'))
  const WaterflowPage = lazy(() => import('../modules/apps/waterflow/WaterflowPage'))
  const IamPage = lazy(() => import('../modules/apps/iam/IamPage'))
  const ProductPage = lazy(() => import('../modules/apps/product/ProductPage'))
  const OutletPage = lazy(() => import('../modules/apps/outlet/OutletPage'))
  const DevicePage = lazy(() => import('../modules/apps/device/DevicePage'))

  return (
    <Routes>
      <Route element={<MasterLayout/>}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard'/>}/>
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper/>}/>
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper/>
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage/>}/>
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage/>
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage/>
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage/>
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage/>
            </SuspensedView>
          }
        />
        <Route
          path='chat/*'
          element={
            <SuspensedView>
              <ChatPage/>
            </SuspensedView>
          }
        />
        <Route
          path='customers'
          element={<Outlet/>}
        >
          <Route
            path='customer-data'
            element={
              <SuspensedView>
                <CustomersPage/>
              </SuspensedView>
            }
          />
          <Route
            path='loyalty-point'
            element={
              <SuspensedView>
                <LoyaltyPage/>
              </SuspensedView>
            }
          />
        </Route>
        <Route
          path='device-management'
          element={<Outlet/>}
        >
          <Route
            path='devices'
            element={
              <SuspensedView>
                <DevicePage/>
              </SuspensedView>
            }
          />
        </Route>
        <Route
          path='transactions'
          element={<Outlet/>}
        >
          <Route
            path='pos'
            element={
              <SuspensedView>
                <PosPage/>
              </SuspensedView>
            }
          />
          <Route
            path='transaction-history'
            element={
              <SuspensedView>
                <TransactionPage/>
              </SuspensedView>
            }
          />
        </Route>
        <Route
          path='configuration'
          element={<Outlet/>}
        >
          <Route
            path='iam'
            element={
              <SuspensedView>
                <IamPage/>
              </SuspensedView>
            }
          />
          <Route
            path='cost'
            element={
              <SuspensedView>
                <CostPage/>
              </SuspensedView>
            }
          />
          <Route
            path='waterflow'
            element={
              <SuspensedView>
                <WaterflowPage/>
              </SuspensedView>
            }
          />
        </Route>
        <Route
          path='products/*'
          element={
            <SuspensedView>
              <ProductPage/>
            </SuspensedView>
          }
        />
        <Route
          path='outlets/*'
          element={
            <SuspensedView>
              <OutletPage/>
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404'/>}/>
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}

import {Dispatch, SetStateAction} from 'react'
import {BaseResponse} from "../../partials/services/BaseResponse";

export type ID = undefined | null | string | number

export type PaginationState = {
  page: number
  limit: 10 | 30 | 50 | 100 | number
  links?: Array<{ label: string; active: boolean; url: string | null; page: number | null }>
}

export type WITPaginationState = {
  /**
   * page: current page
   * totalCount: total data
   * pageSize: limit
   */
  page: number
  totalCount: number
  pageSize: number
}

export type SortState = {
  sort?: string
  order?: 'ASC' | 'DESC'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: any
  updateState: (updates: Partial<any>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {
  },
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: any
}

export type CustomQueryResponseContextProps<T> = {
  response?: BaseResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: any
}

export const initialQueryResponse = {
  refetch: () => {
  }, isLoading: false, query: ''
}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemForUpdate?: any
  setItemForUpdate: Dispatch<SetStateAction<any>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {
  },
  onSelectAll: () => {
  },
  clearSelected: () => {
  },
  setItemIdForUpdate: () => {
  },
  setItemForUpdate: () => {
  },
  isAllSelected: false,
  disabled: false,
}

const QUERIES = {
  USERS_LIST: 'users-list',
  CUSTOMERS_LIST: 'transactions-list',
  PRODUCTS_LIST: 'products-list',
  DEVICES_LIST: 'devices-list',
  OUTLETS_LIST: 'outlets-list',
  TRANSACTIONS_LIST: 'transactions-list',
  COST_CONFIGURATIONS_LIST: 'cost-configurations-list',
  PAYMENT_CATEGORIES_LIST: 'payment-categories-list',
}

export {QUERIES}

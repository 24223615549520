/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      {/*<AsideMenuItem to='/builder' icon='switch' title='Layout Builder' />*/}
      <AsideMenuItemWithSub to='/customers' title='Customers' icon='people'>
        <AsideMenuItem to='/customers/customer-data' icon='profile-user' title='Customer Data'/>
        {/*<AsideMenuItem to='/customers/loyalty-point' icon='cup' title='Loyalty Point' />*/}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/transactions' title='Transactions' icon='two-credit-cart'>
        <AsideMenuItem to='/transactions/pos' icon='handcart' title='Point of Sales'/>
        <AsideMenuItem to='/transactions/transaction-history' icon='cheque' title='Transaction History'/>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/device-management' title='Device Management' icon='router'>
        <AsideMenuItem to='/device-management/devices' icon='external-drive' title='Devices'/>
        {/*<AsideMenuItem to='/crafted/widgets/charts' icon='shield-tick' title='Water Level Display' />*/}
        {/*<AsideMenuItem to='/crafted/widgets/statistics' icon='shield-tick' title='Device Health' />*/}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/configuration' title='Configuration' icon='setting-3'>
        <AsideMenuItem to='/configuration/cost' icon='tag' title='Cost Configuration'/>
        <AsideMenuItem to='/configuration/waterflow' icon='milk' title='Waterflow Limit Configuration'/>
        <AsideMenuItem to='/configuration/iam' icon='security-user' title='IAM Configuration'/>
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/products/list'
        icon='parcel'
        title='Products'
      />

      <AsideMenuItem
        to='/outlets/list'
        icon='pointers'
        title='Outlets'
        />

      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<AsideMenuItemWithSub to='/crafted/pages' title='Pages' icon='gift'>*/}
      {/*  <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>*/}
      {/*    <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />*/}
      {/*    <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />*/}
      {/*    <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />*/}
      {/*    <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />*/}
      {/*    <AsideMenuItem*/}
      {/*      to='/crafted/pages/profile/connections'*/}
      {/*      title='Connections'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*  </AsideMenuItemWithSub>*/}

      {/*  <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>*/}
      {/*    <AsideMenuItem*/}
      {/*      to='/crafted/pages/wizards/horizontal'*/}
      {/*      title='Horizontal'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*    <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />*/}
      {/*  </AsideMenuItemWithSub>*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' icon='profile-circle'>*/}
      {/*  <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItemWithSub to='/error' title='Errors' icon='cross-circle'>*/}
      {/*  <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' icon='element-plus'>*/}
      {/*  <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<AsideMenuItemWithSub to='/apps/chat' title='Chat' icon='message-text-2'>*/}
      {/*  <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />*/}
      {/*  <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

      {/*<div className='menu-item'>*/}
      {/*  <a*/}
      {/*    target='_blank'*/}
      {/*    className='menu-link'*/}
      {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
      {/*  >*/}
      {/*    <span className='menu-icon'>*/}
      {/*      <KTIcon iconName='document' className='fs-2' />*/}
      {/*    </span>*/}
      {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </>
  )
}
